<script setup lang="ts">
import type { CleverTapMessage } from './types';
import EVENTS, { SECTIONS } from '~/constants/events';
import type { Promotion } from '@tn/shared';

type NotificationActionParams = {
  actionType: 'copy' | 'url';
  clipBoardText?: string;
  url?: string;
};

const { $sentry, $notificationClicked, $trackEvent } = useNuxtApp();

const copied = ref(false);

const props = defineProps<{
  webInboxMessage: Partial<CleverTapMessage>;
  tags?: string[];
  notificationId: string;
  activePromotions: Promotion[];
}>();

const currentPromotion = computed(() => {
  return props.activePromotions.find(
    (promotion) =>
      props.webInboxMessage.title &&
      promotion?.brand?.name &&
      props.webInboxMessage.title.includes(promotion.brand.name)
  );
});

const copyToClipboard = async (text?: string) => {
  if (!text) return;
  try {
    copied.value = true;
    await navigator?.clipboard?.writeText(text);
    setTimeout(() => {
      copied.value = false;
    }, 5000);
  } catch (error) {
    $sentry.captureException(error);
    console.error('Failed to copy discount code to clipboard:', error);
  }
};

const onNotificationClicked = ({
  actionType,
  clipBoardText,
  url,
}: NotificationActionParams) => {
  const isPromotionCampaign =
    currentPromotion.value ||
    props.activePromotions.find((item) => item?.code === clipBoardText);

  if (actionType === 'copy' && clipBoardText) {
    copyToClipboard(clipBoardText);
  } else if (actionType === 'url' && url) {
    window.open(url, '_blank');
  }

  if (isPromotionCampaign) {
    const eventName =
      actionType === 'copy'
        ? EVENTS.CODE_COPY_CLICKED
        : EVENTS.REDEEM_CODE_CLICKED;

    $trackEvent(eventName, {
      section: SECTIONS.WEB_INBOX,
      promotion_title: props.webInboxMessage.title,
      promotion_url: url,
      brand_id: currentPromotion.value?.brand?.id,
      brand_name: currentPromotion.value?.brand?.name,
    });
  }

  $trackEvent(EVENTS.MEMBER_INBOX_CONTENT_CLICKED, {
    content_title: props.webInboxMessage.title,
    promotion_content: isPromotionCampaign ? 'yes' : 'no',
    ...(props.tags?.[0] && {
      content_tag: props.tags?.[0],
    }),
    ...(currentPromotion.value && {
      brand_id: currentPromotion.value?.brand?.id,
      brand_name: currentPromotion.value?.brand?.name,
    }),
  });

  $notificationClicked(props.notificationId);
};
</script>

<template>
  <div
    class="w-full rounded-[20px] border border-b-2 border-black bg-white sm:w-[528px]"
    :class="[!webInboxMessage?.imageUrl ? 'pt-6' : null]"
  >
    <img
      v-if="webInboxMessage?.imageUrl"
      :src="webInboxMessage?.imageUrl"
      alt="Notification Image"
      class="notification-image mb-4 rounded-t-[20px]"
    />

    <div class="flex flex-col gap-2 px-4 pb-6">
      <div v-if="tags" class="flex flex-wrap gap-2">
        <div
          v-for="tag in tags"
          :key="tag"
          class="bg-green-150 text border border-b-2 border-black px-2 pb-1.5 pt-1 text-sm text-green-500"
        >
          {{ tag }}
        </div>
      </div>

      <h1 class="font-poppins font-semibold text-neutral-900">
        {{ webInboxMessage?.title }}
      </h1>
      <p class="font-poppins text-sm font-normal text-neutral-900">
        {{ webInboxMessage.description }}
      </p>

      <div
        v-for="button in webInboxMessage?.buttons"
        :key="button.text"
        class="mt-2 flex flex-col items-center justify-center"
      >
        <UButton
          block
          color="tertiary"
          size="xs"
          :disabled="button.action === 'copy' && copied"
          @click="
            onNotificationClicked({
              actionType: button.action,
              clipBoardText: button?.clipboardText,
              url: button?.url,
            })
          "
        >
          {{ button.text }}
        </UButton>
      </div>
    </div>
  </div>
</template>
