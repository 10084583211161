<script setup lang="ts">
import IconAlertCircle from '~/assets/icons/alert-circle.svg?componentext';
import IconMailEnvelope from '@/assets/icons/mail-envelope.svg?skipsvgo';
import { sendEmailVerification } from 'firebase/auth';

const DEFAULT_COUNTDOWN_TIME = 60;
const notify = useNotifications();
const user = useCurrentUser();
const { $sentry } = useNuxtApp();
const { timeRemaining, countdownActive, startCountdown } = useCountdown(
  DEFAULT_COUNTDOWN_TIME
);
const isModalOpen = ref(false);
const checkingVerificationStatus = ref(false);
const resendingVerificationEmail = ref(false);
const hasResentEmail = ref(false);
const errorVerifying = ref(false);

const emit = defineEmits(['verify']);

const onClickAlreadyVerified = async () => {
  try {
    checkingVerificationStatus.value = true;
    hasResentEmail.value = false;
    await user.value?.reload();

    if (user.value?.emailVerified) {
      emit('verify');
      isModalOpen.value = false;
      notify.showSuccessNotification({
        description: `Your email address has now been verified!`,
      });
    } else {
      errorVerifying.value = true;
    }
  } catch (error) {
    notify.showErrorNotification({
      description:
        'An error occurred while verifying your email. Please try again.',
    });
    console.error(error);
    $sentry.captureException(error);
  } finally {
    checkingVerificationStatus.value = false;
  }
};

const emailVerificationRedirectUrl = computed(
  () => `${getBaseUrl()}?email_verified=true`
);

const resendButtonLabel = computed(() => {
  return timeRemaining.value < 1
    ? 'Resend Email'
    : `Resend Email in ${timeRemaining.value}s`;
});

const onClickResendVerificationEmail = async () => {
  if (!user?.value) {
    notify.showErrorNotification({
      description: 'User not found. Please log in again.',
    });
    return;
  }

  try {
    resendingVerificationEmail.value = true;
    await sendEmailVerification(user.value, {
      url: emailVerificationRedirectUrl.value,
      handleCodeInApp: true,
    });
    startCountdown();
    hasResentEmail.value = true;
  } catch (error) {
    notify.showErrorNotification({
      description:
        'An error occurred while verifying your email. Please try again.',
    });
    console.error(error);
    $sentry.captureException(error);
  } finally {
    resendingVerificationEmail.value = false;
    errorVerifying.value = false;
  }
};

const resetStates = () => {
  errorVerifying.value = false;
  checkingVerificationStatus.value = false;
  resendingVerificationEmail.value = false;
  hasResentEmail.value = false;
};

const onCloseModal = () => {
  resetStates();
};
</script>

<template>
  <div class="relative z-10 mt-6 px-6 md:mt-3 lg:px-0">
    <div
      class="max-w-app text-tnNeutral-800 mx-auto flex items-center justify-center rounded-lg border border-b-[3px] border-black bg-red-300 px-2 py-1.5 text-center text-sm font-medium md:px-6"
    >
      <IconAlertCircle class="!mb-0 hidden h-5 w-5 md:block" />
      <p class="ml-2 mr-4">
        You will be unable to place orders until you verify your email
      </p>
      <UButton
        color="secondary"
        label="Verify Email"
        class="px-3 py-1"
        @click="isModalOpen = true"
      />
    </div>
  </div>
  <UModal
    v-model="isModalOpen"
    :ui="{
      background: 'bg-transparent',
      shadow: 'shadow-none',
    }"
    @close="onCloseModal"
  >
    <div
      class="rounded-[20px] border-2 border-b-4 border-black bg-white px-4 py-10 text-center"
    >
      <div class="mx-auto max-w-[270px]">
        <IconMailEnvelope />
      </div>
      <div class="text-tnNeutral-800 my-6">
        <h3 class="font-roca mb-2 text-2xl font-bold">
          For the security of your account, we need to verify your email
        </h3>
        <p class="text-tnNeutral-600 text-lg leading-tight">
          We've sent an email verification link to
          <strong class="text-tnNeutral-900 font-medium">{{
            user?.email
          }}</strong>
        </p>
      </div>
      <div
        class="flex flex-col items-center justify-center gap-x-4 gap-y-2 sm:flex-row"
      >
        <UButton
          color="secondary"
          label="I Already Verified"
          :loading="checkingVerificationStatus"
          :disabled="resendingVerificationEmail"
          @click="onClickAlreadyVerified"
        />
        <UButton
          color="primary"
          :label="resendButtonLabel"
          :loading="resendingVerificationEmail"
          :disabled="checkingVerificationStatus || countdownActive"
          @click="onClickResendVerificationEmail"
        />
      </div>
      <p v-if="hasResentEmail" class="mt-4 text-sm font-medium text-green-600">
        Email has been resent. Please check your inbox.
      </p>
      <p
        v-else-if="errorVerifying"
        class="mt-4 text-sm font-medium text-red-700"
      >
        We couldn't verify your email. Please try again.
      </p>
    </div>
  </UModal>
</template>
