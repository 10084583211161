<script setup lang="ts">
import IconPointsStar from '@/assets/icons/points-star.svg?skipsvgo';
import { EVENTS } from '~/constants/events';
import { usePoints } from '~/features/points';

defineProps<{
  buttonClass?: string;
  spanClass?: string;
}>();

const { $trackEvent } = useNuxtApp();
const route = useRoute();
const { userCurrentPoints } = usePoints();

const onPointsClicked = () => {
  $trackEvent(EVENTS.REWARD_POINTS_CLICKED, {
    page: route.meta.eventPageName,
  });
};
</script>

<template>
  <NuxtLink
    :to="{ name: 'earn-points' }"
    class="xs:w-auto w-full"
    @click="onPointsClicked"
  >
    <button
      :class="buttonClass"
      class="flex items-center justify-center gap-2 rounded-full border border-b-2 border-blue-800 bg-blue-500 px-3 py-1 text-sm font-medium text-blue-50 transition-all hover:bg-blue-600"
    >
      <span :class="spanClass">Points</span>
      <div class="flex items-center gap-1">
        <IconPointsStar class="size-4" />
        <span class="font-poppins font-semibold">{{ userCurrentPoints }}</span>
      </div>
    </button>
  </NuxtLink>
</template>
