<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
});

const { isEmailVerified, isBrand } = useUserState();
const attrs = useAttrs();
const isHeaderDialogOpen = ref(false);

const { isActivePremium } = usePremiumCheck();

const route = useRoute();
const hasVerifiedEmailLocally = ref(false);

watch(
  () => route.fullPath,
  () => {
    isHeaderDialogOpen.value = false;
  }
);

const { isImpersonating } = useImpersonation();

const auth = useFirebaseAuth();

const shouldShowVerifyEmailAlert = computed(() => {
  if (hasVerifiedEmailLocally.value) {
    return false;
  }

  return !isEmailVerified.value && isActivePremium.value;
});

const onVerify = async () => {
  await auth?.currentUser?.reload();

  if (auth?.currentUser?.emailVerified) {
    hasVerifiedEmailLocally.value = true;
  }
};
</script>

<template>
  <header
    class="bg-beige-400 sticky top-0 z-20 border-b-2 border-black md:border-0 md:bg-transparent"
    v-bind="attrs"
  >
    <TnImpersonateBanner v-if="isImpersonating" />
    <div
      v-else-if="isBrand"
      class="flex items-center justify-center space-x-4 border-b border-black bg-white p-2 text-sm"
    >
      <strong
        class="inline-flex flex-shrink-0 items-center space-x-2 font-medium"
      >
        <UIcon name="i-heroicons-eye" class="h-5 w-5" />
        <span>Sampler View</span>
      </strong>
      <span class="truncate"
        >You are viewing the platform as a sampler and not all features will be
        available to you.</span
      >
    </div>

    <div class="md:hidden">
      <TnHeaderMobile />
    </div>
    <div class="hidden md:block">
      <TnHeaderDesktop />
    </div>
  </header>
  <div v-if="shouldShowVerifyEmailAlert">
    <VerifyEmailAlert @verify="onVerify" />
  </div>
</template>
