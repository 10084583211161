import {NuxtIcon} from "#components";
import {h} from "vue";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 14 12"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      d: "M13.667 4.667V2c0-.733-.6-1.333-1.333-1.333H1.667C.934.667.341 1.267.341 2v2.667a1.333 1.333 0 0 1-.007 2.667V10c0 .733.6 1.333 1.333 1.333h10.667c.733 0 1.333-.6 1.333-1.333V7.333c-.733 0-1.333-.6-1.333-1.333s.6-1.333 1.333-1.333m-1.333-.974A2.68 2.68 0 0 0 11.001 6c0 .98.54 1.847 1.333 2.307V10H1.667V8.307A2.68 2.68 0 0 0 3.001 6c0-.987-.534-1.847-1.327-2.307L1.667 2h10.667zM5.047 8.667l1.954-1.254 1.953 1.254-.593-2.24 1.793-1.467-2.313-.14L7 2.667l-.847 2.146-2.313.14L5.634 6.42z"
    }, null, -1)
  ])))
}
export default { render() { return h(NuxtIcon, {icon: {render}, name: "for-you"}) } }