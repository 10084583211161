<script setup lang="ts">
import IconUserAvatar from '@/assets/icons/user-avatar.svg?componentext';
import { useLogoutMutation } from '~/features/auth';

const { email } = useUserState();
const { logout, logoutText, isLoggingOut } = useLogoutMutation();
</script>

<template>
  <UPopover
    :popper="{
      placement: 'bottom',
      arrow: false,
      offsetDistance: 5,
    }"
    :ui="{
      ring: 'ring-0 border border-b-4 border-r-4 border-black',
      rounded: 'rounded-xl',
    }"
  >
    <div class="flex items-center gap-1.5">
      <IconUserAvatar class="!mb-0 size-4" />
      <span>My Account</span>
      <span class="inline-block size-2">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 6 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.805073 1.60924L2.53174 3.33591C2.79174 3.59591 3.21174 3.59591 3.47174 3.33591L5.19841 1.60924C5.61841 1.18924 5.31841 0.469238 4.72507 0.469238H1.27174C0.678407 0.469238 0.385073 1.18924 0.805073 1.60924Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </div>

    <template #panel>
      <div class="flex max-w-[320px] flex-col gap-4 overflow-ellipsis p-4">
        <div
          v-if="email"
          class="flex max-w-[320px] flex-row items-center space-x-2 overflow-ellipsis text-black"
        >
          <UIcon name="i-heroicons-user-circle" class="h-7 w-7" />
          <UTooltip
            :text="email"
            :ui="{
              wrapper: 'relative max-w-[200px] overflow-hidden',
              base: 'h-auto px-2 py-1 text-xs text-center max-w-[320px] whitespace-normal break-words',
            }"
          >
            <p class="truncate text-base font-normal">
              {{ email }}
            </p>
          </UTooltip>
        </div>
        <UButton
          block
          icon="i-heroicons-arrow-right-end-on-rectangle"
          color="secondary"
          :label="logoutText"
          :loading="isLoggingOut"
          @click="logout"
        >
        </UButton>
      </div>
    </template>
  </UPopover>
</template>
