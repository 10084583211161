<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core';
import type { CleverTapNotification } from './types';
import EVENTS from '~/constants/events';
import { usePromotions } from '~/features/promotions/members';

const { activePromotions, suspense } = usePromotions();

await suspense();

const isOpen = defineModel<boolean>({
  default: false,
});

const breakpoints = useBreakpoints({
  mobile: 0,
  tablet: 640,
  laptop: 1024,
  desktop: 1280,
});

const isMobile = breakpoints.smaller('tablet');
const {
  $webInboxMessages,
  $markMessageAsRead,
  $trackEvent,
  $unreadMessagesCount,
} = useNuxtApp();

const allInboxMessages = ref<Record<string, CleverTapNotification> | undefined>(
  undefined
);

const tags = computed(() => {
  if (!allInboxMessages.value) return [];

  const tagsArray = Object.values(allInboxMessages.value).flatMap(
    (inboxMessage) => inboxMessage.tags
  );

  const uniqueTags = [...new Set(['All', ...tagsArray])];
  return uniqueTags;
});

const selectedTags = ref<Record<string, boolean>>({ All: true });

const root = ref<HTMLElement | null>(null);
const filteredMessagesRoot = ref<HTMLElement | null>(null);

const seenNotifications = reactive<Record<string, boolean>>({});

function markNotificationAsRead(key: string): void {
  if (!seenNotifications[key] && allInboxMessages.value?.[key].viewed === 0) {
    seenNotifications[key] = true;
    $markMessageAsRead(key);
  }
}

async function waitForImagesToLoad(container: HTMLElement): Promise<void> {
  const images = Array.from(container.querySelectorAll('img'));

  if (images.length === 0) {
    return Promise.resolve();
  }

  await Promise.all(
    images.map((img) => {
      if (img.complete) {
        return Promise.resolve();
      }
      return new Promise((resolve) => {
        img.addEventListener('load', resolve, { once: true });
        img.addEventListener('error', resolve, { once: true });
      });
    })
  );
}

async function setupObserversAsync() {
  const rootElement = root.value;
  const filteredMessagesContainer = filteredMessagesRoot.value;

  if (!filteredMessagesContainer) {
    console.warn('Filtered messages container is not set.');
    return;
  }

  if (!filteredInboxMessages.value) {
    console.warn('No inbox messages available to observe.');
    return;
  }

  await waitForImagesToLoad(filteredMessagesContainer);

  if (filteredInboxMessages.value) {
    Object.entries(filteredInboxMessages.value).forEach(([key]) => {
      const element = document.querySelector(
        `[data-key="${key}"]`
      ) as HTMLElement | null;

      if (!element) {
        console.warn(
          `Notification with key "${key}" is missing or not rendered.`
        );
        return;
      }

      useIntersectionObserver(
        element,
        ([entry]) => {
          if (entry.isIntersecting) {
            markNotificationAsRead(key);
          }
        },
        {
          root: rootElement,
          threshold: 1.0,
        }
      );
    });
  }
}

const onTagClicked = (tag: string) => {
  selectedTags.value = { [tag]: true };
};

const filteredInboxMessages = computed(() => {
  if (!allInboxMessages.value) return null;

  const activeTags = Object.entries(selectedTags.value)
    .filter(([_, isActive]) => isActive)
    .map(([tag]) => tag);

  if (activeTags.length === 0 || activeTags.includes('All')) {
    return allInboxMessages.value;
  }

  const filteredMessages = Object.entries(allInboxMessages.value).reduce(
    (result, [key, message]) => {
      if (message.tags.some((tag) => activeTags.includes(tag))) {
        result[key] = message;
      }
      return result;
    },
    {} as Record<string, CleverTapNotification>
  );

  return filteredMessages;
});

watch(isOpen, async (newVal) => {
  if (newVal) {
    allInboxMessages.value = $webInboxMessages.value
      ? $webInboxMessages.value
      : undefined;
    $trackEvent(EVENTS.MEMBER_INBOX_CONTENT_LOADED, {
      unread_messages: $unreadMessagesCount.value,
    });
  }
});

watch(filteredInboxMessages, async (messages) => {
  if (messages) {
    await nextTick();
    await setupObserversAsync();
  }
});

const onCloseClicked = () => {
  isOpen.value = false;
  $trackEvent(EVENTS.MEMBER_INBOX_CONTENT_CLOSED);
};
</script>

<template>
  <UModal
    v-model="isOpen"
    :fullscreen="isMobile"
    :ui="{
      base: 'sm:min-w-[560px] sm:rounded-[20px] max-h-full',
      background: 'bg-beige-100',
      shadow: 'shadow-none',
    }"
  >
    <div
      ref="root"
      class="bg-beige-100 flex max-h-full flex-col gap-4 overflow-y-auto p-4 sm:rounded-[20px]"
      :class="[
        allInboxMessages && Object.keys(allInboxMessages).length > 0
          ? 'sm:h-[764px]'
          : null,
      ]"
    >
      <div class="mb-1.5 flex w-full items-center justify-between">
        <h1 class="self-start text-2xl font-bold">Taste Exclusives</h1>
        <button class="flex items-center" @click="onCloseClicked">
          <UIcon name="i-heroicons-x-mark" class="h-6 w-6"></UIcon>
        </button>
      </div>

      <div class="flex flex-col gap-4">
        <div v-if="tags && tags.length > 1" class="flex flex-wrap gap-2">
          <UButton
            v-for="tag in tags"
            :key="tag"
            class="font-primary"
            size="xs"
            :color="selectedTags[tag] ? 'primary' : 'secondary'"
            @click="onTagClicked(tag)"
          >
            {{ tag }}
          </UButton>
        </div>
        <div
          v-if="
            filteredInboxMessages &&
            Object.keys(filteredInboxMessages).length > 0
          "
          ref="filteredMessagesRoot"
          class="flex max-h-full flex-col gap-4 overflow-y-auto"
        >
          <WebInboxCard
            v-for="[key, value] in Object.entries(filteredInboxMessages)"
            :key="key"
            :notification-id="value.wzrk_id"
            :web-inbox-message="value.msg[0]"
            :active-promotions="activePromotions"
            :tags="value.tags"
            :data-key="key"
          />
        </div>
        <WebInboxEmpty v-else />
      </div>
    </div>
  </UModal>
</template>
