import {NuxtIcon} from "#components";
import {h} from "vue";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9 .667A8.336 8.336 0 0 0 .665 9c0 4.6 3.733 8.333 8.333 8.333S17.333 13.6 17.333 9 13.599.667 8.999.667m-3.876 13.75A6.6 6.6 0 0 1 9 13.167c1.45 0 2.784.466 3.875 1.25A6.6 6.6 0 0 1 9 15.667a6.6 6.6 0 0 1-3.875-1.25m8.992-1.15a8.29 8.29 0 0 0-10.233 0A6.63 6.63 0 0 1 2.333 9a6.665 6.665 0 0 1 6.666-6.667A6.665 6.665 0 0 1 15.666 9a6.63 6.63 0 0 1-1.55 4.267"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9 4a2.92 2.92 0 0 0-2.917 2.917 2.92 2.92 0 0 0 2.916 2.916 2.92 2.92 0 0 0 2.917-2.916A2.92 2.92 0 0 0 8.999 4m0 4.167c-.692 0-1.25-.559-1.25-1.25 0-.692.558-1.25 1.25-1.25.691 0 1.25.558 1.25 1.25 0 .691-.559 1.25-1.25 1.25"
    }, null, -1)
  ])))
}
export default { render() { return h(NuxtIcon, {icon: {render}, name: "user-avatar"}) } }