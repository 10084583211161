import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 12 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.99935 2.99992H8.66602C8.66602 1.52659 7.47268 0.333252 5.99935 0.333252C4.52602 0.333252 3.33268 1.52659 3.33268 2.99992H1.99935C1.26602 2.99992 0.666016 3.59992 0.666016 4.33325V12.3333C0.666016 13.0666 1.26602 13.6666 1.99935 13.6666H9.99935C10.7327 13.6666 11.3327 13.0666 11.3327 12.3333V4.33325C11.3327 3.59992 10.7327 2.99992 9.99935 2.99992ZM5.99935 1.66659C6.73268 1.66659 7.33268 2.26659 7.33268 2.99992H4.66602C4.66602 2.26659 5.26602 1.66659 5.99935 1.66659ZM9.99935 12.3333H1.99935V4.33325H3.33268V5.66659C3.33268 6.03325 3.63268 6.33325 3.99935 6.33325C4.36602 6.33325 4.66602 6.03325 4.66602 5.66659V4.33325H7.33268V5.66659C7.33268 6.03325 7.63268 6.33325 7.99935 6.33325C8.36602 6.33325 8.66602 6.03325 8.66602 5.66659V4.33325H9.99935V12.3333Z",
      fill: "black"
    }, null, -1)
  ])))
}
export default { render: render }