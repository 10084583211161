import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 14 12",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M13.6673 4.66675V2.00008C13.6673 1.26675 13.0673 0.666748 12.334 0.666748H1.66732C0.933984 0.666748 0.340651 1.26675 0.340651 2.00008V4.66675C1.07398 4.66675 1.66732 5.26675 1.66732 6.00008C1.66732 6.73342 1.07398 7.33342 0.333984 7.33342V10.0001C0.333984 10.7334 0.933984 11.3334 1.66732 11.3334H12.334C13.0673 11.3334 13.6673 10.7334 13.6673 10.0001V7.33342C12.934 7.33342 12.334 6.73342 12.334 6.00008C12.334 5.26675 12.934 4.66675 13.6673 4.66675ZM12.334 3.69341C11.5407 4.15341 11.0007 5.02008 11.0007 6.00008C11.0007 6.98008 11.5407 7.84675 12.334 8.30675V10.0001H1.66732V8.30675C2.46065 7.84675 3.00065 6.98008 3.00065 6.00008C3.00065 5.01341 2.46732 4.15341 1.67398 3.69341L1.66732 2.00008H12.334V3.69341ZM5.04732 8.66675L7.00065 7.41341L8.95399 8.66675L8.36065 6.42675L10.154 4.96008L7.84065 4.82008L7.00065 2.66675L6.15398 4.81342L3.84065 4.95341L5.63398 6.42008L5.04732 8.66675Z",
      fill: "black"
    }, null, -1)
  ])))
}
export default { render: render }