<script setup lang="ts">
import IconShoppingCart from '@/assets/icons/shopping-cart.svg?skipsvgo';
import { CartModal } from '#components';
import EVENTS from '~/constants/events';
import { useCart } from '~/features/cart';

const { $trackEvent } = useNuxtApp();
const route = useRoute();
const modal = useModal();
const { cartItemsCount } = useCart();

const cartClicked = () => {
  modal.open(CartModal);

  $trackEvent(EVENTS.NAVIGATIONAL_CART_CLICKED, {
    page: route.meta.eventPageName,
    cart_product_count: cartItemsCount.value,
  });
};

defineProps<{
  buttonClass?: string;
  spanClass?: string;
}>();
</script>

<template>
  <UChip
    size="3xl"
    :text="cartItemsCount"
    :show="!!cartItemsCount"
    color="blue"
  >
    <button
      :class="buttonClass"
      class="bg-beige-200 flex items-center justify-center gap-1 rounded-full border border-b-2 border-black px-3 py-1 text-sm font-medium text-black transition-all hover:bg-white"
      @click="cartClicked"
    >
      <IconShoppingCart class="size-4" />
      <span :class="spanClass" class="font-poppins font-semibold">Cart</span>
    </button>
  </UChip>
</template>
