<script setup lang="ts">
import IconToken from '@/assets/icons/token.svg?skipsvgo';
import { useUserReviews } from '~/features/reviews/members';
import { useTokens } from '~/features/tokens';

const { shouldShowRenewBanner } = usePremiumCheck();
const { validTokenCount } = useTokens();
const { pendingReviewsAmount } = useUserReviews();

const shouldShowPopover = computed(() => {
  return shouldShowRenewBanner.value || !!pendingReviewsAmount.value;
});

defineProps<{
  buttonClass?: string;
  spanClass?: string;
}>();
</script>

<template>
  <UPopover
    :open="shouldShowPopover"
    :popper="{ arrow: true, placement: 'bottom', offsetDistance: 20 }"
    class="xs:w-auto w-full"
    :ui="{
      background: 'bg-yellow-500',
      ring: 'ring-0 border border-b-4 border-r-4 border-black',
      shadow: 'shadow-none',
      rounded: 'rounded-lg',
      arrow: {
        background: 'before:bg-yellow-500',
        ring: 'before:ring-0 before:border before:border-b-0 before:border-black',
      },
    }"
  >
    <NuxtLink :to="{ name: 'wallet' }" class="w-full">
      <button
        :class="buttonClass"
        class="relative flex items-center justify-center gap-2 rounded-full border border-b-2 border-yellow-600 bg-yellow-400 px-3 py-1 text-sm font-medium text-black transition-all hover:bg-yellow-500"
      >
        <span :class="spanClass">Wallet</span>
        <div class="flex items-center gap-1">
          <IconToken class="size-4" />
          <span class="font-poppins font-semibold">{{ validTokenCount }}</span>
        </div>
      </button>
    </NuxtLink>

    <template #panel>
      <div
        v-if="pendingReviewsAmount"
        class="font-secondary p-3 text-xs font-semibold"
      >
        You have {{ pendingReviewsAmount }}
        {{ pendingReviewsAmount === 1 ? 'review' : 'reviews' }}
        to complete
      </div>
      <PremiumRenewBanner v-else />
    </template>
  </UPopover>
</template>
