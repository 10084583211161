<script setup lang="ts"></script>

<template>
  <div class="mb-6 flex flex-col items-center gap-1">
    <img src="~/assets/web-inbox-empty.png" alt="Web Inbox Empty Image" />
    <h2 class="font-poppins mt-5 text-lg font-semibold text-neutral-900">
      Personalized picks, coming soon! 🎯
    </h2>
    <p class="px-5 text-center text-sm text-neutral-900 sm:px-0">
      Check back soon for content curated just for you.
    </p>
  </div>
</template>
