<script setup lang="ts">
import EVENTS from '~/constants/events';

const { $trackEvent } = useNuxtApp();
const isNotificationModalOpen = ref(false);

const { $unreadMessagesCount } = useNuxtApp();

const onOpenWebInboxModal = () => {
  isNotificationModalOpen.value = true;
  $trackEvent(EVENTS.MEMBER_INBOX_OPENED);
};
</script>

<template>
  <div>
    <slot
      :unread-message-count="$unreadMessagesCount"
      :open-modal="onOpenWebInboxModal"
    />
    <WebInboxModal v-model="isNotificationModalOpen" />
  </div>
</template>
