<script setup lang="ts">
import { HEADERLINKS } from '~/constants/headerLinks';
import NavWalletButton from '~/components/tn/nav/NavWalletButton.vue';
import NavPointsButton from '~/components/tn/nav/NavPointsButton.vue';
import NavCartButton from '~/components/tn/nav/NavCartButton.vue';
import NavForYouButton from '~/components/tn/nav/NavForYouButton.vue';
import { useLogoutMutation } from '~/features/auth';
import { flagsConfig, RewardSystemPromotions } from '@tn/shared';

const { $getVariant } = useNuxtApp();

const links = computed(() => {
  const variant = $getVariant(flagsConfig.REWARD_SYSTEM_PROMOTIONS);

  return variant?.value === RewardSystemPromotions.WebInbox
    ? HEADERLINKS.filter((link) => link.label !== 'Promotions')
    : HEADERLINKS;
});

const route = useRoute();
const isHeaderDialogOpen = ref(false);
const { email, canSeeRewardSystem } = useUserState();
const { logout, logoutText, isLoggingOut } = useLogoutMutation();

watch(
  () => route.fullPath,
  () => {
    isHeaderDialogOpen.value = false;
  }
);
</script>

<template>
  <div class="max-w-app mx-auto w-full py-3">
    <USlideover
      v-model="isHeaderDialogOpen"
      side="left"
      :ui="{
        background: 'bg-beige-300',
      }"
    >
      <div class="relative flex-1 overflow-auto p-4 pt-16">
        <button
          class="absolute end-4 top-4 text-2xl font-bold text-black"
          @click="isHeaderDialogOpen = false"
        >
          <UIcon name="i-heroicons-x-mark" />
        </button>

        <NuxtLink to="/" class="mx-auto block max-w-[150px]">
          <img
            src="@/assets/taste-network-logo.svg"
            alt="Taste Network Logo"
            class="w-full object-contain"
          />
        </NuxtLink>

        <div class="my-6 flex flex-col gap-4 text-center text-lg font-medium">
          <NuxtLink
            class="text-tnNeutral-900/70 hover:text-tnNeutral-900"
            to="/"
            >Home</NuxtLink
          >
          <NuxtLink
            v-for="link in links"
            :key="link.label"
            :to="link.to"
            class="text-tnNeutral-900/70 hover:text-tnNeutral-900"
          >
            {{ link.label }}
          </NuxtLink>
        </div>
        <div v-if="email" class="border-t border-black/[25%] pt-4">
          <div
            class="text-tnNeutral-900/50 flex items-center justify-center gap-2 font-medium"
          >
            <UIcon name="i-heroicons-user-circle" class="h-7 w-7" />
            <span>{{ email }}</span>
          </div>

          <div class="mt-3 text-center">
            <UButton
              icon="i-heroicons-arrow-right-end-on-rectangle"
              color="secondary"
              :label="logoutText"
              :loading="isLoggingOut"
              @click="logout"
            >
            </UButton>
          </div>
        </div>
      </div>
    </USlideover>
    <nav
      class="relative mb-3 flex h-12 items-center justify-between rounded-full border-0 px-4 py-2"
      aria-label="Global"
    >
      <div class="flex items-center">
        <UButton
          v-if="links?.length"
          variant="link"
          color="black"
          :aria-label="`${isHeaderDialogOpen ? 'Close' : 'Open'} Menu`"
          icon="i-heroicons-bars-3-20-solid"
          @click="isHeaderDialogOpen = !isHeaderDialogOpen"
        />

        <NuxtLink to="/" class="ml-2">
          <img src="@/assets/logo-bare.svg" alt="Taste Network Logo" />
        </NuxtLink>
      </div>
      <div class="flex items-center gap-3">
        <NavForYouButton span-class="hidden min-[425px]:block" />
        <NavCartButton span-class="hidden min-[425px]:block" />
      </div>
    </nav>

    <div class="xs:justify-end flex items-center gap-2 px-4">
      <NavPointsButton v-if="canSeeRewardSystem" button-class="w-full" />
      <NavWalletButton button-class="w-full block" />
    </div>
  </div>
</template>
