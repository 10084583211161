<script setup lang="ts">
import IconForYou from '@/assets/icons/for-you.svg?skipsvgo';
import type { RemoteConfig } from '@tn/shared';

const remoteConfig = useState<RemoteConfig>('remote-config');

const isForYouPageEnabled = computed(
  () => remoteConfig?.value?.forYouPage?.isEnabled
);

defineProps<{
  buttonClass?: string;
  spanClass?: string;
}>();
</script>

<template>
  <WebInboxWrapper>
    <template #default="{ unreadMessageCount, openModal }">
      <UChip
        v-if="isForYouPageEnabled"
        size="3xl"
        :text="unreadMessageCount"
        :show="!!unreadMessageCount"
        color="blue"
      >
        <button
          :class="buttonClass"
          class="bg-beige-200 flex items-center justify-center gap-1 rounded-full border border-b-2 border-black px-3 py-1 text-sm font-medium text-black transition-all hover:bg-white"
          @click="openModal"
        >
          <IconForYou class="size-4" />
          <span :class="spanClass" class="font-poppins font-semibold"
            >Taste Exclusives</span
          >
        </button>
      </UChip>
    </template>
  </WebInboxWrapper>
</template>
